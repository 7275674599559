@mixin unlist($margin: true, $padding: true) {
  @if $margin {
    @if $margin == 'vertical' {
      @include flush--ends;
    } @else if $margin == 'horizontal' {
      @include flush--sides;
    } @else {
      margin: 0;
    }
  }
  @if $padding {
    padding: 0;
  }

  list-style: none;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  user-select: none;
}

@mixin font-size($sizes) {
  @each $breakpoint, $size in $sizes {
    @if $breakpoint == base {
      font-size: $size;
    } @else {
      @include mq($breakpoint) {
        font-size: $size;
      }
    }
  }
}

@mixin selection {
  ::-moz-selection {
    @content;
  }

  ::selection {
    @content;
  }
}
