@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.expertice {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
    gap: 50px;

    @include mq($to: xl) {
      display: block;
    }

    @include mq($to: lg) {
      display: flex;
      flex-direction: column-reverse;
      align-items: stretch;
      gap: 30px;
    }
  }

  &__list-wrap {
    @include mq(lg) {
      display: block !important;
      height: auto !important;
      overflow: visible !important;
    }
  }

  &__cell {
    width: calc(33.3333% - 20px);

    @include mq($to: lg) {
      width: auto;

      &:last-child {
        #{$block-name} {
          &__link-wrap {
            // border-bottom-style: dashed;
          }
        }
      }
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    @include mq($to: lg) {
      display: block;
    }
  }

  &__inner {
    padding: 80px 60px 60px;
    color: $white-100;
    border-radius: 20px;
    background: linear-gradient(262deg, #351ba4 0%, #613bd8 100%);

    @include mq($to: xl) {
      padding: 50px 40px;
    }

    @include mq($to: lg) {
      padding: 20px;
    }
  }

  &__item {
    &--submenu-opened {
      #{$block-name} {
        &__toggler-btn {
          background-color: $green-400;

          svg {
            transform: scale(1, -1);
          }
        }
      }
    }
  }

  &__left {
    display: flex;
    align-items: flex-end;
    width: calc(39% - 25px);

    @include mq($to: xl) {
      width: auto;
    }
  }

  &__link {
    display: inline-flex;
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 14px 0;
    transition: color $transition-time;
    color: $white-100;
    text-decoration: none;
    padding-right: 26px;

    @include mq($to: lg) {
      padding: 20px 0;
    }

    &:hover {
      #{$block-name} {
        &__image {
          opacity: 1;
        }

        &__item-title {
          transform: translate3d(37px, 0, 0);

          @include mq($to: lg) {
            transform: translate3d(0, 0, 0);
          }
        }

        &__item-icon {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    &:active {
      color: $green-200;
    }
  }

  &__image {
    position: absolute;
    top: 16px;
    left: 0;
    opacity: 0;
    transition: opacity $transition-time--slow;

    @include mq($to: lg) {
      display: none;
    }
  }

  &__item-title {
    transform: translate3d(0, 0, 0);
    transition: transform $transition-time--slow;
  }

  &__item-icon {
    position: absolute;
    top: 18px;
    right: 0;
    opacity: 0;
    transform: translate3d(-10px, 10px, 0);
    transition:
      opacity $transition-time--slow,
      transform $transition-time--slow;

    @include mq($to: lg) {
      display: none;
    }
  }

  &__link-wrap {
    border-bottom: 1px solid rgb(255 255 255 / 20%);
  }

  &__list {
    @include unlist;

    padding-top: 38px;

    @include mq($to: xl) {
      padding-top: 26px;
    }
  }

  &__list-item {
    position: relative;
    font-family: $font-family;
    font-size: $font-size-xs;
    font-style: normal;
    font-weight: $font-weight-s;
    line-height: $line-height-l;
    padding-left: 24px;
    color: $white-300;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 14px;
      height: 5px;
      background-color: $green-200;
      border-radius: 13px;
      margin-top: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }

  &__number-one {
    color: $green-200;
    font-family: $font-family--headings;
    font-size: 128px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -3.84px;

    @include mq($to: xl) {
      margin-right: 20px;
    }

    @include mq($to: lg) {
      font-size: 90px;
      flex: 0 0 auto;
    }
  }

  &__right {
    width: calc(61% - 25px);

    @include mq($to: xl) {
      width: auto;
    }
  }

  &__text-wrap {
  }

  &__text-box {
    margin-bottom: 6px;

    @include mq($to: xl) {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }

    @include mq($to: lg) {
      margin-bottom: 0;
    }
  }

  &__text {
    opacity: 0.8;
  }

  &__title {
    margin-bottom: 50px;

    @include mq($to: xl) {
      margin-bottom: 30px;
    }

    @include mq($to: lg) {
      margin-bottom: 20px;
    }
  }

  &__title-s {
    margin-bottom: 4px;

    @include mq($to: xl) {
    }
  }

  &__toggler {
    display: none;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    width: 60.8px;
    height: 60.8px;

    @include mq($to: lg) {
      display: flex;
      height: 74.8px;
    }
  }

  &__toggler-btn {
    @include reset-btn;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: $border-radius-rounded;
    background-color: $purple-300;
    color: $white-100;
    transition: background-color $transition-time;

    svg {
      transform: scale(1, 1);
      transition: transform 0.2s ease 0s;
      stroke-width: 1.5;
    }
  }

  &__button {
    display: none;
    margin-top: 26px;
    margin-bottom: 10px;

    @include mq($to: lg) {
      display: block;
    }
  }
}
